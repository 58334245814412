<template>
    <a-card :border="false">
        <div class="realtor">
            <!-- 查询表单 -->
            <a-form-model ref="searchForm" layout="horizontal" :label-col="{xs:8}" :wrapper-col="{xs:16}">
                <a-row :gutter="0">
                    <a-col :md="8" :xl="6" :xxl="4" :sm="12">
                        <a-form-model-item label="名称">
                            <a-input v-model="searchData.name" allowClear placeholder="名称"></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :xxl="4" :xl="5" :md="8" sm="2">
                        <a-form-model-item :label-col="{span: 0}" :wrapper-col="{span:24}">
                            <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
                            <a-button type="default" @click="reset()" icon="sync">重置</a-button>
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>
            <div>
                <a-button type="primary" @click="toHandler('add')" v-if="isAdd">新增</a-button>
                <a-button type="primary" @click="toHandler('edit')" v-if="isEdit">修改</a-button>
                <a-button type="primary" @click="toHandler('del')" v-if="isDelete">删除</a-button>
            </div>
            <!-- 数据表格 -->
            <a-table :loading="tableLoading" :row-selection="rowSelection" :rowKey="record => record.id"
                     :columns="columns" :data-source="tableData" bordered :pagination="page"
                     @change="changeTable" :customRow="changeTableRow">
            </a-table>
            <!-- 编辑表单 -->
            <ProductFunctionType-edit-modal ref="ProductFunctionTypeEditModal" @reload="getData"></ProductFunctionType-edit-modal>
        </div>
    </a-card>
</template>

<script>
import { columns } from './components/colums.js'
import ProductFunctionTypeEditModal from './components/ProductFunctionTypeEditModal.vue'
import { delProductFunctionType, listProductFunctionType, selectByIdProductFunctionType } from './api/ProductFunctionTypeApi'
import { checkPermission } from '@/utils/permissions'

const pageSource = {
    current: 1,
    pageSize: 10,
    total: 0
}
export default {
    name: 'productFunctionType',
    components: {
        ProductFunctionTypeEditModal
    },
    data() {
        return {
            columns: columns,
            tableData: [],
            searchData: {}, // 搜索条件
            scroll:{ y: 600 },
            tableLoading: false, //表格loading
            page: Object.assign({}, pageSource),
            selectedRowKeys: [],
            selectedRows: [],
            isAdd: checkPermission('product/function_type:add'),
            isEdit: checkPermission('product/function_type:edit'),
            isDelete: checkPermission('product/function_type:delete')
        }
    },
    computed: {
        rowSelection() {
            const _this = this
            return {
                fixed: true,
                type: 'radio', //"radio"
                selectedRowKeys: this.selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    _this.selectedRowKeys = selectedRowKeys
                    _this.selectedRows = selectedRows
                }
            }
        }
    },
    methods:{
        /**
         * 获取表格数据
         */
        getData() {
            this.tableLoading = true
            listProductFunctionType ({
                pageNumber: this.page.current,
                pageSize: this.page.pageSize,
                ...this.searchData
            }).then(res => {
                if (res.code === 200) {
                    const { total, records } = res.body
                    this.page.total = total
                    this.tableData = records
                  this.selectedRowKeys = []
                  this.selectedRows = []
                }
            }).finally(() => this.tableLoading = false)
        },
        /**
         * 筛选查询
         */
        getDataFilter() {
            this.page.current = 1;
            this.getData()
        },
        /**
         * 重置
         */
        reset() {
            this.searchData = {}
            this.page.current = 1;
            this.page.pageSize = 10
            // this.page = Object.assign({}, pageSource)
            this.getData()
        },
        /**
         * 更改分页
         */
        changeTable(pagination) {
            this.page = pagination
            this.getData()
        },
        /**
         * 点击行选中
         */
        changeTableRow(record) {
            return {
                on: {
                    click: e => {
                        console.log(e)
                        if (this.selectedRowKeys[0] === record.id) {
                            this.selectedRowKeys = []
                            this.selectedRows = []
                        } else {
                            this.selectedRowKeys = [record.id]
                            this.selectedRows = [record]
                        }
                    }
                }
            }
        },
        toHandler(name) {
            const _this = this
            if (name === 'add') {
                _this.$refs.ProductFunctionTypeEditModal.setRowData({}, 'add')
                return
            }
            if (_this.selectedRows.length === 0) {
                _this.$notification.warning({
                    message: '请选择一条记录'
                })
                return
            }
            switch (name) {
                case 'edit':
                    _this.$refs.ProductFunctionTypeEditModal.setRowData(_this.selectedRows[0], 'edit')
                    break
                case 'del':
                    _this.$confirm({
                        title: '删除警告',
                        content: '确认要删除此条记录吗?',
                        okText: '确认',
                        cancelText: '取消',
                        async onOk() {
                            const res = await delProductFunctionType(_this.selectedRows[0].id)
                            if (res.code === 200) {
                                _this.$notification.success({ message: res.message })
                               _this.getData();
                            } else {
                                _this.$notification.error({ message: res.message })
                            }
                        },
                        onCancel() {}
                    })
                    break
            }
        },
    },
    created() {
        this.getData()
    }
}
</script>

<style lang="less" scoped>
    .ant-btn {
        margin-right: 8px;
        margin-bottom: 12px;
    }
</style>
